

























import { Component, Prop, Vue } from "vue-property-decorator";
import AppAddressBlock from "@/components/core/AppAddressBlock.vue";
import AppWidget from "@/components/core/AppWidget.vue";
import { currentUser } from "@/utils/authentication";
import { FatalError } from "@/utils/exception";

@Component({
  components: {
    AppAddressBlock,
    AppWidget,
  },
})
export default class AppAddressWidget extends Vue {
  @Prop({ type: Object, required: false })
  readonly address!: pro.Address;

  @Prop({ type: String, required: true })
  readonly heading!: string;

  @Prop({ type: Boolean, default: false })
  readonly isStatic!: boolean;

  @Prop({ type: String, required: false })
  readonly addressType!: pro.AddressType;

  @Prop({ type: String, default: "Address not found." })
  readonly errorMessage!: string;

  get userId(): pro.Id {
    const user = currentUser();
    if (!user.id) throw new FatalError();
    return user.id;
  }
}
