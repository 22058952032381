



















import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class AppSelect extends Vue {
  @Prop({ type: String, required: false })
  readonly id!: string;

  @Prop({ type: [String, Number], required: false })
  readonly value!: string | number;

  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean;

  @Prop({ type: String, default: "" })
  readonly placeholder!: string;

  @Prop({ type: String, required: false })
  readonly errorPlaceholder!: string;

  @Prop({ type: Boolean, required: false })
  readonly required!: boolean;

  @Prop({ type: String, required: false })
  readonly helperId!: string;

  get hasOptions(): boolean {
    return !!this.$slots["default"];
  }

  placeholderText(): string {
    if (!this.hasOptions && this.errorPlaceholder) return this.errorPlaceholder;
    return this.placeholder;
  }
}
