


















/**
 * Custom checkbox component
 *
 * @see https://vuejs.org/v2/guide/forms.html#Checkbox for guidance on using
 * v-model
 */
import { Component, Model, Prop, Vue, Watch } from "vue-property-decorator";
import AppLabel from "@/components/core/AppLabel.vue";

@Component({ components: { AppLabel } })
export default class AppCheckbox extends Vue {
  @Prop({ type: String, required: true })
  readonly id!: string;

  @Prop({ type: String || Number, required: false })
  readonly value!: number | string;

  @Prop({ type: Boolean, default: false })
  readonly indeterminate!: boolean;

  @Prop({ type: String, required: false })
  readonly helperId!: boolean;

  @Model("change", { type: Boolean })
  readonly checked!: boolean;

  /**
   * This could be used for something like agreeing to legal terms
   */
  @Prop({ type: Boolean, default: false })
  readonly required!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly hideLabel!: boolean;

  @Watch("indeterminate")
  watchIndeterminate(indeterminate: boolean) {
    this.toggleIndeterminate(indeterminate);
  }

  mounted() {
    this.toggleIndeterminate(this.indeterminate);
  }

  toggleIndeterminate(indeterminate: boolean): void {
    const el = this.element();
    if (!el) return;
    el.indeterminate = indeterminate;
  }

  element(): HTMLInputElement | null {
    return document.getElementById(this.id) as HTMLInputElement;
  }
}
