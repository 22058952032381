






















































































































import { Component, Prop, Vue } from "vue-property-decorator";
import AppBadge from "@/components/core/AppBadge.vue";
import AppButton from "@/components/core/AppButton.vue";
import AppCheckbox from "@/components/core/AppCheckbox.vue";
import AppCounter from "@/components/core/AppCounter.vue";
import AppSelect from "@/components/core/AppSelect.vue";

@Component({
  components: {
    AppBadge,
    AppButton,
    AppCheckbox,
    AppCounter,
    AppSelect,
  },
})
export default class AppLineItem extends Vue {
  @Prop({ type: [String, Number], required: false })
  readonly catalogId!: pro.Id;

  @Prop({ type: [String, Number], required: false })
  readonly seriesId!: pro.Id;

  /**
   * Initial line item data
   */
  @Prop({ required: false })
  readonly initialLine!: pro.Line;

  /**
   * An optional list of selectable units for this line item
   */
  @Prop({ type: Array, required: false })
  readonly initialUnits!: pro.Unit[];

  /**
   * Whether the line item is selected
   */
  @Prop({ type: Boolean, default: false })
  readonly isSelected!: boolean;

  /**
   * Whether the line is disabled
   */
  @Prop({ type: Boolean, default: false })
  readonly isDisabled!: boolean;

  /**
   * Whether the line can be added to the cart
   */
  @Prop({ type: Boolean, default: false })
  readonly lineCanBeAdded!: boolean;

  /**
   * Whether the line can be removed from the cart
   */
  @Prop({ type: Boolean, default: false })
  readonly lineCanBeRemoved!: boolean;

  /**
   * Whether the line can be updated
   */
  @Prop({ type: Boolean, default: false })
  readonly lineCanBeUpdated!: boolean;

  /**
   * Whether the line is actually a list heading
   */
  @Prop({ type: Boolean, default: false })
  readonly isHeader!: boolean;

  /**
   * Whether the line item is selectable
   */
  @Prop({ type: Boolean, default: false })
  readonly isSelectable!: boolean;

  /**
   * Whether the Line should contain a link to the Item page
   */
  @Prop({ type: Boolean, default: false })
  readonly showLinkToItemPage!: boolean;

  /**
   * Whether the line item status should be hidden
   */
  @Prop({ type: Boolean, default: false })
  readonly hideStatus!: boolean;

  /**
   * Whether the selection checkbox should be marked as indeterminate
   */
  @Prop({ type: Boolean, default: false })
  readonly isIndeterminate!: boolean;

  @Prop({ type: Boolean, default: true })
  readonly hasQty!: boolean;

  /**
   * Generates a unique ID for the line selection checkbox
   */
  get checkboxId(): string {
    if (!this.line || !this.line.id) return "line-heading-checkbox";
    return this.line.id + "-checkbox";
  }

  /**
   * Checks whether the line item has a fulfillment status
   */
  get hasStatus(): boolean {
    if (this.hideStatus || !this.line || !this.line.order) return false;
    return true;
  }

  /**
   * Returns the Item's default Unit, if one exists
   */
  get defaultUnit(): pro.Unit | undefined {
    if (
      this.initialLine &&
      this.initialLine.unit &&
      this.initialLine.unit.item &&
      this.initialLine.unit.item.defaultUnit
    ) {
      return this.initialLine.unit.item.defaultUnit;
    }
    return undefined;
  }

  line: pro.Line | null = null;

  mounted() {
    if (!this.isHeader) this.configureLine();
  }

  configureLine(): void {
    const line: pro.Line = this.initialLine ?? {};
    if (this.unitIsRequestable(this.defaultUnit))
      line.unit = { ...this.initialLine.unit, id: this.defaultUnit?.id };
    if (this.lineCanBeUpdated) line.qty = line.qty ?? 1;
    this.line = line;
  }

  /**
   * Checks whether a Unit is requestable
   */
  unitIsRequestable(unit?: pro.Unit): boolean {
    if (!unit || !this.initialUnits || this.initialUnits.length < 1)
      return false;
    unit = Object.assign({}, unit);
    const isRequestable = this.initialUnits
      .filter((el: pro.Unit) => el.id == unit?.id)
      .some((el: pro.Unit) => !el.disabled && !el.backordered);
    return isRequestable;
  }

  /**
   * Adds a line to the cart
   */
  addToCart(): void {
    if (!this.line || !this.line.unit || !this.line.unit.item) return;

    const unit = this.availableUnits().find(
      (el: pro.Unit) => el.id === this.line?.unit?.id
    );

    this.line = {
      ...this.line,
      unit: { ...unit, item: this.line.unit.item },
    };

    this.$emit("add", this.line);
  }

  /**
   * Filters the available units
   */
  availableUnits(): pro.Unit[] {
    if (this.initialUnits) return this.initialUnits;
    else if (this.line && this.line.unit) return [this.line.unit];
    return [];
  }

  /**
   * Whether the line selection checkbox should be disabled
   */
  disableSelection(): boolean {
    if (this.isCancelled() || this.isShipped()) return true;
    return false;
  }

  /**
   * Checks whether the line item has been cancelled
   */
  isCancelled(): boolean {
    if (!this.initialLine) return false;
    return typeof this.initialLine.cancellation !== "undefined";
  }

  /**
   * Checks whether the line item has been shipped
   */
  isShipped(): boolean {
    if (!this.initialLine) return false;
    return typeof this.initialLine.shipment !== "undefined";
  }

  /**
   * Selects the line item
   */
  selectLine(e: boolean) {
    this.$emit("line-selected", e);
  }

  /**
   * Sets the line item quantity
   */
  setQty(qty: number): void {
    if (this.line && this.line.qty) {
      this.line.qty = qty;
      this.$emit("updateLineQty", this.line.qty);
    }
  }

  /**
   * Sets the line item's unit of measure
   */
  uom(unit: pro.Unit) {
    if (unit.backordered && this.lineCanBeUpdated)
      return `${unit.uom} - OUT OF STOCK`;
    return unit.uom;
  }
}
