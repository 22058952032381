


























import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class AppAddressBlock extends Vue {
  @Prop({ required: true })
  address!: pro.Address;

  get phoneNumber(): string | null {
    const phone = this.address.phoneNumber;
    if (!phone) return null;
    const match = phone.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (!match) return null;
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }
}
