







import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class AppLabel extends Vue {
  @Prop({ required: true })
  readonly id!: string;

  @Prop({ type: Boolean, default: false })
  readonly required!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly hidden!: boolean;
}
