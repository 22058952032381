
















import { Component, Prop, Vue } from "vue-property-decorator";
import AppAlert from "@/components/core/AppAlert.vue";
import { Notification } from "@/utils/notification";

@Component({ components: { AppAlert } })
export default class AppWidget extends Vue {
  @Prop({ type: String, required: false })
  readonly heading!: string;

  @Prop({ type: Boolean, default: false })
  readonly flush!: boolean;

  @Prop({ type: Object, required: false })
  readonly notification!: Notification;
}
