






import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class AppBadge extends Vue {
  @Prop({ type: String, default: "neutral" })
  readonly kind!: "neutral" | "good" | "warning";
}
