









































































































import { Component, Vue } from "vue-property-decorator";
import AppAddressWidget from "@/components/widgets/AppAddressWidget.vue";
import AppLabel from "@/components/core/AppLabel.vue";
import AppLineItem from "@/components/AppLineItem.vue";
import AppListGroup from "@/components/core/AppListGroup.vue";
import AppListGroupItem from "@/components/core/AppListGroupItem.vue";
import AppParcel from "@/components/AppParcel.vue";
import AppWidget from "@/components/core/AppWidget.vue";
import TheHeader from "@/components/TheHeader.vue";
import TheMain from "@/components/TheMain.vue";
import { findShipment } from "@/utils/api";

@Component({
  components: {
    AppAddressWidget,
    AppLabel,
    AppLineItem,
    AppListGroup,
    AppListGroupItem,
    AppParcel,
    AppWidget,
    TheHeader,
    TheMain,
  },
})
export default class Shipment extends Vue {
  shipment: pro.Shipment | null = null;

  get catalogId(): pro.Id {
    return this.$route.params.catalogId;
  }

  get shipmentId(): pro.Id {
    return this.$route.params.shipmentId;
  }

  mounted() {
    this.findShipment();
  }

  async findShipment(): Promise<void> {
    this.shipment = await findShipment({
      id: this.shipmentId,
      catalog: { id: this.catalogId },
    });
  }
}
