




































import { Component, Prop, Vue } from "vue-property-decorator";
import AppButton from "@/components/core/AppButton.vue";
import AppInput from "@/components/core/AppInput.vue";
import AppInputGroup from "@/components/core/AppInputGroup.vue";

@Component({ components: { AppButton, AppInput, AppInputGroup } })
export default class AppCounter extends Vue {
  @Prop({ type: String, required: false })
  readonly id!: string;

  @Prop({ type: Number, default: 0 })
  readonly min!: number;

  @Prop({ type: Number, required: false })
  readonly max!: number;

  @Prop({ type: Number, default: 1 })
  readonly value!: number;

  update(val: number): void {
    if (this.isAboveMin(val) && this.isBelowMax(val)) this.$emit("input", val);
  }

  isBelowMax(val: number): boolean {
    return typeof this.max === "undefined" || val <= this.max;
  }

  isAboveMin(val: number): boolean {
    return typeof this.min === "undefined" || val >= this.min;
  }
}
