








import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class AppParcel extends Vue {
  @Prop({ required: false })
  readonly parcel!: pro.Parcel;

  @Prop({ type: Boolean, default: false })
  readonly isHeader!: boolean;

  get dimensions() {
    return `${this.parcel.length} x ${this.parcel.width} x ${this.parcel.height} in.`;
  }

  get weight() {
    return `${this.parcel.weight} lb.`;
  }

  get tracking() {
    return this.isHeader ? "Tracking" : this.parcel.tracking;
  }
}
